import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Box, Typography, Grid } from '@mui/material';

import SinunaLine from './ui/SinunaLine';
import SinunaLogo from '../assets/img/logoblackwor.svg';
import { Stack } from '@mui/system';
import { Link } from "react-router-dom";

const links = [
  {key: 'menu.frontpage', path: '/'}, 
];

const Footer = () => {  
    
    return (
      <>
        <Grid container direction="row" mt={15} justifyContent="center" sx={{paddingX: {xs: 2}}}>
          <Grid item md={1} />
          <Grid item md={10}>
            <SinunaLine />

            <Grid container mt={15} mb={8} direction="row">
              <Grid item md={6}>
                <Box>
                  <img src={SinunaLogo} className="vertical-center" alt="Sinuna-logo" height='25px' />
                </Box>

                <Stack direction="row" justifyContent="space-between" mt={3}>
                  <Box>
                    <Typography variant='footerName' sx={{display: 'block'}}>
                      Suomen Tunnistautumisosuuskunta
                    </Typography>
                    <Typography variant='footer' sx={{display: 'block'}}>
                      yhteys@sinuna.fi
                    </Typography>
                  </Box>
                </Stack>
              </Grid>
              <Grid item md={2} />            
              <Grid item md={4}>
                {links.map((page) => (
                  <Box m={1} key={page.path}>
                    <Link to={page.path}>
                      <Typography color='#203CCC' variant='appBarMenuItem'>
                        <FormattedMessage id={page.key} defaultMessage="" />
                      </Typography>
                    </Link>
                  </Box>
                ))}
              </Grid>

            </Grid>

          </Grid>
          <Grid item md={1} />
        </Grid>
      </>
    );
}

export default injectIntl(Footer);