import { createTheme } from '@mui/material';

const sinunaTheme = createTheme({
  palette: {
    primary: {
      main: '#203CCC'
    },
    success: {
      main: '#008445'
    },
    menu: {
      main: '#FFFFFF'
    },
    black: {
      main: '#000000'
    },
    orange: {
      main: '#FF4C23'
    }
  },
  typography: {
    fontFamily: 'Sinuna-regular',
    fontSize: '1.0rem',

    normal: {
      fontFamily: 'Sinuna-regular',
      fontSize: '1.0rem',
    },
    appBarMenuItem: {
      fontFamily: 'Sinuna-semibold',
      fontSize: '14px',
      color: 'black'
    },
    formError: {
      fontFamily: 'Sinuna-medium',
      fontSize: '0.8rem',
      lineHeight: '0.7rem'
    },
    formInstructions: {
      fontFamily: 'Sinuna-regular',
      fontSize: '1.0rem',
      lineHeight: '0.8rem'
    },
    h1: {
      fontFamily: 'Sinuna-semibold',
      fontSize: '2.3rem',
      lineHeight: '2.4rem'
    },
    h3: {
      fontFamily: 'Sinuna-semibold',
      fontSize: '1.0rem',
    },
    heroheading: {
      fontFamily: 'Sinuna-semibold',
      fontSize: '2.3rem',
      lineHeight: '2.4rem'
    },
    herobody: {
      fontFamily: 'Sinuna-regular',
      fontSize: '1.2rem',
      lineHeight: '1.8rem'
    },
    h4: {
      fontWeight: 400,
      fontSize: '1.9rem'
    },
    h5: {
      fontWeight: 500
    },
    h6: {
      fontWeight: 500
    },
    heroheadingbig: {
      fontFamily: 'Sinuna-semibold',
      fontSize: '2.0em',
      lineHeight: '2.1rem',
      '@media (min-width: 500px)': {
        fontSize: '4.0rem',
        lineHeight: '4.1rem'
      },
      color: 'white',
    },
    heroheadingbigblack: {
      fontFamily: 'Sinuna-semibold',
      fontSize: '2.0em',
      lineHeight: '2.5rem',
      '@media (min-width: 500px)': {
        fontSize: '4.0rem',
        lineHeight: '4.5rem'
      },
      color: 'black',
    },
    herobodywhite: {
      fontFamily: 'Sinuna-regular',
      fontSize: '1.7rem',
      lineHeight: '1.8rem',
      color: 'white',
    },
    body1: {
      fontFamily: 'Sinuna-medium',
      fontSize: '1.2rem',
      lineHeight: '1.2rem'
    },
    body2: {
      fontFamily: 'Sinuna-regular',
      fontSize: '1.1rem',
      lineHeight: '1.1rem'
    },
    body3: {
      fontFamily: 'Sinuna-regular',
      fontSize: '0.8rem',
    },
    body4: {
      fontFamily: 'Sinuna-regular',
      fontSize: '0.7rem',
      lineHeight: '0.7rem',
    },
    footer: {
      fontFamily: 'Sinuna-regular',
      fontSize: '1.0rem',
      lineHeight: '1.5rem',
    },
    footerName: {
      fontFamily: 'Sinuna-medium',
      fontSize: '1.0rem',
      lineHeight: '1.5rem',
    },
    menuItem: {
      fontFamily: 'Sinuna-medium',
      fontSize: '1.6rem',
      lineHeight: '1.6rem'
    },

  }
})

  export default sinunaTheme;