import React from 'react';
//import { useNavigate } from "react-router-dom";
import { FormattedMessage, injectIntl } from 'react-intl';
import { Box, Typography, Button } from '@mui/material';
import Drawer from '@mui/material/Drawer';
//import SinunaLogo from '../assets/img/sinunalogo-bs.svg';
import { Link } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';

const DrawerMenu = (props) => {  
  const { toggleMenu } = props;

    return (
      <Drawer
        anchor='right'
        open={props.menuOpen}
        onClose={props.toggleMenu(false)}
        sx={{
          display: { xs: 'block' },
          '& .MuiDrawer-paper': { bgcolor: '#ffffff', boxSizing: 'border-box', width: 400 },
        }}
      >
        <Box m={3}>
          <Box textAlign='right'>
            <Button onClick={toggleMenu(false)} aria-label='Sulje valikko'>
              <CloseIcon style={{color: 'black'}}/>
            </Button>
          </Box>
          <Box mt={3} textAlign='center'>
            {props.pages.map((page) => (
              <Box mt={2} key={page.path}>
                <Link to={page.path}>
                  <Typography variant='manuItem'>
                    <FormattedMessage id={page.key} defaultMessage="" />
                  </Typography>
                </Link>
              </Box>
            ))}
          </Box>
        </Box>
      </Drawer>
    );
}

export default injectIntl(DrawerMenu);