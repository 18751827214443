import * as React from 'react';
import { injectIntl } from 'react-intl';

import '../App.css';

import Footer from '../components/Footer';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import FrontPageHero from '../components/ui/FrontPageHero';

function FrontPage(props) {
  return (
    <>
      <ResponsiveAppBar selectLanguage={props.selectLanguage} language={props.language}/>
      <FrontPageHero />
      {/* <FrontPageBenefits /> */}
      <Footer />
    </>
  );
}

export default injectIntl(FrontPage);