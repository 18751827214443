import * as React from 'react';
import { Container } from '@mui/material';
import { injectIntl } from 'react-intl';
import '../App.css';

import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import Footer from '../components/Footer';
import i18nAttribute from '../utils/i18nAttribute';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import SimplePageHeader from '../components/ui/SimplePageHeader';

import { page } from './notificationContent';

function ClosureNotificationPage(props) {

  return (
    <>
        <ResponsiveAppBar selectLanguage={props.selectLanguage} language={props.language}/>
          <SimplePageHeader 
            heading={i18nAttribute({ attribute: 'heading', locale: props.language, data: page.data[0] })}
            ingress={i18nAttribute({ attribute: 'ingress', locale: props.language, data: page.data[0] })}
          />
          {/* <Typography variant="h1">
            {i18nAttribute({ attribute: 'heading', locale: props.language, data: pages.data[0] })}
          </Typography> */}
          <Container maxWidth="md">
            <ReactMarkdown children={i18nAttribute({ attribute: 'content', locale: props.language, data: page.data[0] })} remarkPlugins={[remarkGfm]} />
          </Container>
        <Footer />
    </>
  );
}

export default injectIntl(ClosureNotificationPage);