import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import MuiButton from '@mui/material/Button';
import { styled } from "@mui/material/styles";
import { injectIntl } from 'react-intl';

export const Button = styled(MuiButton)(() => ({
  fontSize: '16px',
  textTransform: 'none',
  fontFamily: 'Sinuna-medium',
  whiteSpace: 'nowrap',
  minWidth: 'max-content',
}));

const SimplePageHeader = ({heading, ingress}) => {  
    
    return (
        <>
        <Grid container>
          <Grid item xs={12} md={8}>
            <Box p={10} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
              <Stack>
                <Typography variant='heroheadingbigblack'>
                  {heading}
                </Typography>
                <Typography variant='herobody' mt={3}>
                  {ingress}
                </Typography>
              </Stack>
            </Box>
          </Grid>  
          <Grid item xs={12} md={3} >
            <Grid container wrap="nowrap" spacing={0} alignItems='right' height='100%'>
              <Grid item xs={3} backgroundColor='#FF4C23'>
              </Grid>
              <Grid item xs={3} backgroundColor='#008445'>
              </Grid>
              <Grid item xs={3} backgroundColor='#203CCC' >
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
}

export default injectIntl(SimplePageHeader);