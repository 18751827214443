const i18nAttribute = (props) => {
    let { attribute, data, locale } = props;
    const { localizations } = data.attributes;
    if (localizations) {
        for (var l of localizations.data) {
            if (l.attributes.locale === 'en' && locale === 'en') {
                return l.attributes[attribute];
            }
            else if (l.attributes.locale === 'sv-SE' && locale === 'sv') {
                return l.attributes[attribute];
            }
        }
    }
    // if FI or no localization found
    return data.attributes[attribute];
}

export default i18nAttribute;