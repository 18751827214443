import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import LanguageIcon from '@mui/icons-material/Language';
import Container from '@mui/material/Container';
import MuiButton from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';
import { styled } from "@mui/material/styles";
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link, useNavigate } from "react-router-dom";

import SinunaLine from './ui/SinunaLine';
import SinunaLogo from '../assets/img/logoblackwor.svg';
import DrawerMenu from './DrawerMenu';


const pages = [
];

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
};


export const Button = styled(MuiButton)(({ pill }) => ({
  borderRadius: 4,
  color: pill ? 'white' : 'black',
  fontSize: '16px',
  textTransform: 'none',
  fontFamily: 'Sinuna-medium'
}));

function ResponsiveAppBar(props) {
  let navigate = useNavigate();
  const [anchorElLang, setAnchorElLang] = React.useState(null);

  const [menuOpen, setMenuOpen] = React.useState(false);
    
  const toggleMenu = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }  
    setMenuOpen(open);
  };

  const handleOpenLangMenu = (event) => {
    setAnchorElLang(event.currentTarget);
  };

  const handleCloseLangMenu = () => {
    setAnchorElLang(null);
  };

  return (
    <React.Fragment>
    <CssBaseline />
    <HideOnScroll {...props}>
    <AppBar elevation={0} position="sticky" sx={{backgroundColor: 'white'}}>
      <SinunaLine />  
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            onClick={() => navigate("/", { replace: true })}
            sx={{
              mr: 2,
              ml: { xs: 0, md: 10},
              display: { xs: 'flex', md: 'flex' },
              flexGrow: 1,
              textDecoration: 'none',
            }}
          >
            <img src={SinunaLogo} className="vertical-center" alt="Sinuna-logo" height='20px' />
          </Typography>          
          <Box sx={{marginRight: 1, paddingRight: 1}}>
            {props.language === 'fi' && (
              <Button startIcon={<LanguageIcon />} size='small' onClick={handleOpenLangMenu} aria-label="Valitse kieli, välj språk, choose language">
                Suomeksi
              </Button>
            )}
            {props.language === 'sv' && (
              <Button startIcon={<LanguageIcon />} size='small' onClick={handleOpenLangMenu} aria-label="Valitse kieli, välj språk, choose language">
                På svenska
              </Button>
            )}
            {props.language === 'en' && (
              <Button startIcon={<LanguageIcon />} size='small' onClick={handleOpenLangMenu} aria-label="Valitse kieli, välj språk, choose language">
                In English
              </Button>
            )}
          </Box>

          <Box sx={{ flexGrow: 0, alignItems: 'center', display: { xs: 'none', lg: 'flex' }, mr: 10 }}>
            {pages.map((page) => (
              <Box m={1} key={page.path}>
                <Link to={page.path}>
                  <Typography variant='appBarMenuItem'>
                    <FormattedMessage id={page.key} defaultMessage="" />
                  </Typography>
                </Link>
              </Box>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: 'flex', lg: 'none' } }}>
            {/* <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={toggleMenu(true)}
              color="black"
            >
              <MenuIcon />
            </IconButton> */}
            <Menu
              id="langmenu-appbar"
              anchorEl={anchorElLang}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElLang)}
              onClose={handleCloseLangMenu}
              sx={{
                display: 'block',
              }}
            >
              {props.language !== 'fi' && (
                <MenuItem onClick={() => {props.selectLanguage('fi'); handleCloseLangMenu()}} aria-label="Valitse suomen kieli">
                    <Typography variant="body2">Suomeksi</Typography>
                </MenuItem>
              )}
              {props.language !== 'sv' && (
                <MenuItem onClick={() => {props.selectLanguage('sv'); handleCloseLangMenu()}} aria-label="Välja svensk språk">
                  <Typography variant="body2">På svenska</Typography>
                </MenuItem>
              )}
              {props.language !== 'en' && (
                <MenuItem onClick={() => {props.selectLanguage('en'); handleCloseLangMenu()}} aria-label="Choose English language">
                  <Typography variant="body2">In English</Typography>
                </MenuItem>
              )}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
    </HideOnScroll>
    {/* <DrawerMenu pages={pages} menuOpen={menuOpen} toggleMenu={toggleMenu}/> */}
    </React.Fragment>
  );
}
export default injectIntl(ResponsiveAppBar);