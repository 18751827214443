import React from 'react';
import { Grid } from '@mui/material';

const SinunaLine = (props) => {  
    
    return (
        <>        
        <Grid container style={{height: '4px'}}>
          <Grid item xs={6} style={{height: '4px', backgroundColor: '#293BC7'}} />
          <Grid item xs={2} style={{height: '4px', backgroundColor: '#008445'}} />
          <Grid item xs={2} style={{height: '4px', backgroundColor: '#D98C09'}} />
          <Grid item xs={2} style={{height: '4px', backgroundColor: '#FF4C23'}} />
        </Grid>
      </>
    );
}

export default SinunaLine;