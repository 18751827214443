export const page = {
  "data": [
      {
          "attributes": {
              "__typename": "Page",
              "heading": "Kotimainen Sinuna–kirjautumispalvelu lakkautetaan",
              "ingress": "Sinuna-kirjautumispalvelun kahden vuoden pilotointijakso ei täyttänyt sille asetettuja tavoitteita ja se on päätetty lakkauttaa 28. helmikuuta 2024.\n",
              "content": "Suomen Tunnistautumisosuuskunnan kehittämä, salasanat korvaava Sinuna-kirjautuminen kehitettiin etenkin mediatoimialan tarpeisiin, mahdollistamaan kirjautuminen useisiin kotimaisiin palveluihin ja käyttäjien yksityisyyden turvaavaksi vaihtoehdoksi esimerkiksi Google- ja Facebook-kirjautumiselle. Palvelu ei kuitenkaan onnistunut saavuttamaan markkinoilla odotettua kiinnostusta,  eikä palvelua käyttävien organisaatioiden ja yksittäisten käyttäjien määrä noussut tavoitteiden mukaiselle tasolle.\n\nSinuna-kirjautumisen kehittämiseen oli sitouduttu kahden vuoden ajaksi, mutta tulosten jäätyä vaatimattomiksi ei palvelun ylläpito ja kehittäminen nykyisellä kustannusrakenteella ollut enää taloudellisesti mahdollista.\n\nYhteiskunnallinen tarve datavastuulliselle ja riippumattomalle kirjautumisratkaisulle on edelleen olemassa, ja se koskee sekä vaihtoehtoja perinteiselle salasanakirjautumiselle että vahvaa tunnistautumista. Sinuna-kirjautumisratkaisun osalta kuitenkin vaikuttaa siltä, että markkinoiden valmius uudenlaiseen lähestymistapaan ei ole vielä riittävällä tasolla.\n\nSinuna-tunnuksella kirjautuneet käyttäjät voivat jatkaa palveluiden käyttöä kirjautumalla tavalliseen tapaan palvelukohtaisilla tunnuksilla. Sinuna-tunnuksen käyttäjistä on kerätty vain vähimmäismäärä henkilötietoja ja kaikki tiedot hävitetään palvelun sulkemisen yhteydessä.\n\nSinuna-kirjautumispalvelu on suljettu 28.2.2024.",
              "slug": "sulkeutumistiedote",
              "localizations": {
                  "data": [
                      {
                          "attributes": {
                              "__typename": "Page",
                              "heading": "Den inhemska Sinuna-inloggningstjänsten kommer att upphöra",
                              "ingress": "Den tvååriga pilotperioden för Sinuna-inloggningstjänsten uppfyllde inte de mål som satts upp för den, och det har beslutats att avbryta den den 28 februari 2024.",
                              "content": "Sinuna-inloggningen, utvecklad av Finlands Autentiseringsandelslag, som ersätter lösenord, har utvecklats speciellt för mediebranschens behov, för att möjliggöra inloggning till flera inhemska tjänster och som ett alternativ till exempelvis Google- och Facebook-inloggningar som skyddar användarnas Integritet. Tjänsten lyckades dock inte uppnå det förväntade intresset på marknaden och antalet organisationer och enskilda användare som använde tjänsten steg inte till målnivån.\n\nUtvecklingen av Sinuna-inloggningen hade åtagit sig under en period av två år, men efter att resultaten förblev blygsamma var det inte längre ekonomiskt möjligt att underhålla och utveckla tjänsten med nuvarande kostnadsstruktur.\n\nDet samhälleliga behovet av en dataansvarig och oberoende inloggningslösning finns fortfarande och det handlar både om alternativ till traditionella lösenordsinloggningar och stark autentisering. När det gäller Sinuna inloggningslösning verkar det dock som att marknadens beredskap för ett nytt tillvägagångssätt ännu inte är på en tillräcklig nivå.\n\nAnvändare som har loggat in med Sinuna-kontot kan fortsätta använda tjänsterna i framtiden genom att logga in som vanligt med tjänstespecifika konto. Endast en minimal mängd personuppgifter har samlats in från användare av Sinuna-konto, och all data kommer att förstöras när tjänsten stängs.\n\nSinuna-inloggningstjänst är stängd från den 28 februari 2024.",
                              "locale": "sv-SE"
                          }
                      },
                      {
                          "attributes": {
                              "__typename": "Page",
                              "heading": "The domestic Sinuna log-in service will be discontinued",
                              "ingress": "The two-year pilot period of the Sinuna login service did not meet the goals set for it, and it has been decided to discontinue it on February 28, 2024.",
                              "content": "The Sinuna login solution, created by the Finnish Authentication Cooperative specifically for the media industry, aimed to replace traditional passwords. It was designed to allow access to various domestic services and offered a privacy-conscious alternative to logins like those from Google and Facebook. Despite these intentions, the service didn't garner the anticipated market interest, and both organizational and individual user numbers fell short of expectations.\n\nInitially committed to a two-year development phase, the Sinuna login struggled with modest results, making it financially unsustainable to continue its maintenance and development under the existing cost structure.\n\nThere remains a societal need for a login solution that is both data-responsible and independent, addressing the need for alternatives to traditional password logins and enhancing strong authentication. However, for the Sinuna login solution, the market doesn't seem ready yet for such a new approach.\n\nUsers of the Sinuna ID can keep accessing services with their service-specific IDs as before. The system only gathered minimal personal data from users, which will be deleted once the service is shut down.\n\nThe Sinuna login service has been discontinued on February 28, 2024.",
                              "locale": "en"
                          }
                      }
                  ]
              }
          }
      }
  ]
}