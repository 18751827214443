import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import MuiButton from '@mui/material/Button';
import { styled } from "@mui/material/styles";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useNavigate } from "react-router-dom";

export const Button = styled(MuiButton)(() => ({
  fontSize: '16px',
  textTransform: 'none',
  fontFamily: 'Sinuna-medium',
  whiteSpace: 'nowrap',
  minWidth: 'max-content',
}));

const FrontPageHero = (props) => {  
  let navigate = useNavigate();

    return (
        <>
        <Grid container sx={{height: '70vh'}}>
          <Grid item xs={12} md={9} >
            <Box p={{xs: 5, sm: 10}} sx={{ marginRight: '-1px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: {xs: '', md: '18px 0 0 18px'}, backgroundColor: '#001a45', height: '100%', marginLeft: {xs: 0, md: '30px'}}}>
              <Stack>
                <Typography variant='heroheadingbig' color='white'>
                  <FormattedMessage id="frontpage.hero.heading" defaultMessage="" />
                  {/* {xsScreen ? 'Tieto-vastuullinen' : 'Tietovastuullinen'} {xsScreen ? 'kirjautumis-ratkaisu' : 'kirjautumisratkaisu'} */}
                </Typography>
                <Typography variant='herobody' mt={3} color='white'>
                  <FormattedMessage id="frontpage.hero.body" defaultMessage="" />
                </Typography>

                <Stack direction='row' mt={4}>
                   <Box mr={3}>
                     <Button 
                       variant='outlined' 
                       sx={{borderColor: 'white', color: 'white', '&:hover': {
                        backgroundColor: 'white',
                        color: '#000000',
                      },}}
                       endIcon={<ArrowForwardIcon/>}
                       onClick={() => navigate("/tiedote", { replace: true })}
                     >
                       <FormattedMessage id="frontpage.hero.button1" defaultMessage="" />
                     </Button>
                   </Box>
                   <Box mr={3}>
                     <Button 
                       variant='text' 
                       sx={{color: 'white'}}                    
                       onClick={() => navigate("/ukk", { replace: true })}
                     >
                       <FormattedMessage id="frontpage.hero.button2" defaultMessage="" />
                     </Button>
                   </Box>
                 </Stack>
              </Stack>
            </Box>
          </Grid>  
          <Grid item xs={12} md={3} sx={{backgroundColor: '#001a45'}}>
            <Grid container wrap="nowrap" spacing={0} alignItems='right' height='100%'>
              <Grid item xs={6} />
              <Grid item xs={2} backgroundColor='#FF4C23'>
              </Grid>
              <Grid item xs={2} backgroundColor='#008445'>
              </Grid>
              <Grid item xs={2} backgroundColor='#203CCC' >
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
}

// const FrontPageHero = (props) => {  
//   let navigate = useNavigate();

//     return (
//         <>
//         <Grid container>
//           <Grid item xs={12} md={6}>
//             <Box p={10} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: {xs: '', md: '18px 0 0 18px'}, backgroundColor: '#F9FBFE', height: '100%', marginLeft: {xs: 0, md: '30px'}}}>
//               <Stack>
//               <Typography variant='heroheading'>
//                 <FormattedMessage id="frontpage.hero.heading" defaultMessage="Sinuna on kirjautumispalvelu, joka suojelee yksityisyyttäsi." />
//               </Typography>
              // <Typography variant='herobody' mt={3}>
              // <FormattedMessage id="frontpage.hero.body" defaultMessage="" />
              // </Typography>
//               <Stack direction='row' mt={4}>
//                 <Box mr={3}>
//                   <Button 
//                     variant='contained' 
//                     endIcon={<ArrowForwardIcon/>}
//                     onClick={() => navigate("/tutustu", { replace: true })}
//                   >
//                     <FormattedMessage id="frontpage.hero.button1" defaultMessage="" />
//                   </Button>
//                 </Box>
//               </Stack>
//               </Stack>
//             </Box>
//           </Grid>  
//           <Grid item xs={12} md={6} sx={{backgroundColor: '#F9FBFE'}}>
//             <Grid container wrap="nowrap" spacing={1} alignItems='center'>
//               <Grid item xs={1} sx={{display: {md: 'none'}}}/>
//               <Grid item xs={3} md={3}>
//                 <Stack width='100%' spacing={1}>
//                   <Box sx={{backgroundColor: '#203CCC'}} height='230px' />
//                   <Box width='100%' height='250px'>
//                     <img alt='kuvituskuva' src={FP1Image} style={{objectFit: 'cover', height: '100%', width: '100%'}} />
//                   </Box>
//                 </Stack>
//               </Grid>
//               <Grid item xs={3} md={3}>
//                 <Stack width='100%x' spacing={1}>
//                   <Box height='100px' />
//                   <Box width='100%' height='230px'>
//                     <img alt='kuvituskuva' src={FP2Image} style={{objectFit: 'cover', height: '100%', width: '100%'}} />
//                   </Box>
//                   <Box sx={{backgroundColor: '#FF4C23'}} height='220px' />
//                 </Stack>
//               </Grid>
//               <Grid item xs={3} md={3}>
//                 <Stack width='100%' spacing={1}>
//                   <Box width='100%' height='230px'>
//                     <img alt='kuvituskuva' src={FP3Image} style={{objectFit: 'cover', height: '100%', width: '100%'}} />
//                   </Box>
//                   <Box sx={{backgroundColor: '#008445'}} height='180px' />
//                   <Box heigth='150px' />
//                 </Stack>
//               </Grid>
//               <Grid item xs={1} md={3} />
//             </Grid>
//           </Grid>
//         </Grid>
//       </>
//     );
// }

export default injectIntl(FrontPageHero);