import * as React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import {IntlProvider} from 'react-intl'
import {
  Routes,
  Route,
  useSearchParams
} from "react-router-dom";

import { ThemeProvider } from '@mui/material';
import sinunaTheme from './themes/sinunaTheme';

import messages_fi from "./translations/fi.json";
import messages_sv from "./translations/sv.json";
import messages_en from "./translations/en.json";

import FrontPage from './pages/FrontPage';
import ClosureNotificationPage from './pages/ClosureNotificationPage';
import ClosureFAQPage from './pages/ClosureFAQPage';


const messages = {
    'fi': messages_fi,
    'sv': messages_sv,
    'en': messages_en,
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App(props) {
  const [searchParams] = useSearchParams();

  var initialLanguage = searchParams.get('l');

  if (!(initialLanguage === 'en' || initialLanguage === 'sv')) initialLanguage = 'fi';
  
  let initialI18nConfig = {
    locale: initialLanguage,
    messages: messages[initialLanguage]
  };
  
  const [language, setLanguage] = useState(initialLanguage);
  const [i18nConfig, setI18nConfig] = useState(initialI18nConfig);

  const selectLanguage = (lang) => {
    setLanguage(lang);
    let newI18nConfig = {
      locale: lang,
      messages: messages[lang]
    };

    setI18nConfig(newI18nConfig);
  }

  return (
    <ThemeProvider theme={sinunaTheme}>
      <IntlProvider
        locale={i18nConfig.locale}
        defaultLocale={i18nConfig.locale}
        messages={i18nConfig.messages}
      >
        <ScrollToTop />
        <Routes>
          {/* <Route
            path="/l"
            element={<LandingPage selectLanguage={selectLanguage} language={language} {...props}/>}
          /> */}
          <Route
            path="/"
            element={<FrontPage selectLanguage={selectLanguage} language={language} {...props}/>}
          />
          <Route
            path="/tiedote"
            element={<ClosureNotificationPage selectLanguage={selectLanguage} language={language} {...props}/>}
          />
          <Route
            path="/ukk"
            element={<ClosureFAQPage selectLanguage={selectLanguage} language={language} {...props}/>}
          />
        </Routes>
      </IntlProvider>
    </ThemeProvider>
  );
}

export default App;